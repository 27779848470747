import React, { useState } from 'react';
import { CONTAINER, MYFORM, BUTTON } from './style-components'
import { Container,  Button, Row, Col, Table } from 'react-bootstrap';
import { Predictions, Storage } from 'aws-amplify'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import Thumb from './thumbnail'
import Resizer from './image_resizer';



const validationSchema = Yup.object().shape({

});

const CrowdDetect = (props) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [numPerson, setNumPerson] = useState(0);
  const [confidence, setConfidence] = useState(0);
  const [articlesDetected, setArticlesDetected] = useState(String);
  const [matrix3x3, setmatrix3x3] = useState([]);
  const [unSafe, setUnsafe] = useState(String);

  //let values = JSON.stringify(props.deviceDataValues)
  let numOfPeople = 0;

  if (props.crowdDetectLabels != null) {

    for (var i = 0; i < props.crowdDetectLabels.labels.length; i++) {
      var element = {
        name: props.crowdDetectLabels.labels[i].name,
        count: props.crowdDetectLabels.labels[i].boundingBoxes.length,
        confidence: props.crowdDetectLabels.labels[i].confidence
      }
      if (element.name == "Person") {
        //dataTable.push(element);
        numOfPeople = element.count;
      }


    }
  }

  async function setPhoto(photo)
  {
    let reader = new FileReader();
    var t_image = new Image();
    
    reader.readAsDataURL(photo);
    reader.onloadend = () => {
      
      t_image.src = reader.result;
      t_image.onload =  () => {
        if (t_image.height > 720 || t_image.width > 1280) {

          Resizer.imageFileResizer(
            photo, 1280, 720, 'JPEG', 92, 0,
            uri => {
              console.log("DEBUG: Using Resizer");
              setSelectedPhoto(uri);
              setmatrix3x3([]);
            },
            'blob'
          );

        }
        else
        {
          setSelectedPhoto(photo);
          setmatrix3x3([]);
        }

      }
    };
  }

  async function analyzePhoto() {
    //await props.uploadCrowdDetectPhoto(selectedPhoto);
    var file = selectedPhoto;

    let imageId = (new Date().getTime()/1000).toFixed(0);
      
    Storage.put('analyze-'+imageId+'-crowd.jpg', selectedPhoto , {level:'public',
      contentType: 'image/jpeg'
    })
    .then (result => {
      console.log(result);
      console.log("S3 URL:   "+"https://ctuserstoragedevl-devl.s3.amazonaws.com/public/"+'analyze-'+imageId+'-crowd.jpg');
    })
    .catch(err => {
      alert("Upload Error");
      console.log(err);
    });

    Predictions.identify({
      labels: {
        source: {
          file
        },
        type: "ALL"
      }
    })
      .then((response) => {
        alert("Crowd Detection Complete");
        //setCrowdDetectLabels(response);
        //debugger;
        var miscObjects = "Person";
        var matrix3x3_t = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        const { labels } = response;
        const { unsafe } = response; // boolean 
        setUnsafe(unsafe);
        labels.forEach(object => {
          const { name, boundingBoxes, metadata } = object
          console.log(name + ":" + JSON.stringify(boundingBoxes) + ":" + JSON.stringify(metadata));
          if (name === "Person") {
            setNumPerson(boundingBoxes.length)
            setConfidence(metadata.confidence)
            for (var i = 0; i < boundingBoxes.length; i++) {
              var value = Math.min(2, Math.round(boundingBoxes[i].left * (3))) + Math.min(2, Math.round(boundingBoxes[i].top * (3))) * 3;
              matrix3x3_t[value] += 1;
            }
          }
          else {
            miscObjects += (", " + name);
          }
        });
        setArticlesDetected(miscObjects);
        setmatrix3x3(matrix3x3_t);
        //debugger;            
        console.log({ response });
        for (var i = 0; i < response.labels.length; i++) {
          var element = {
            name: response.labels[i].name,
            count: response.labels[i].boundingBoxes.length,
            confidence: response.labels[i].confidence
          }
          if (element.name == "Person") {
            //dataTable.push(element);
            setNumPerson(element.count);
          }

        }
      }
      )
      .catch(err => {
        alert("Crowd Detection Error");
        console.log({ err })
      }
      );
  }




  //debugger;
  return (

    <CONTAINER>

<Container className="text-center" style={{ width: '90%' }} >
        <Row>
          <Col sm className="text-center">

      <Formik
        initialValues={{ photo: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {

          alert("Submitting!");// When button submits form and form is in the process of submitting, submit button is disabled
          props.uploadCrowdDetectPhoto(values.photo);

          //setSubmitting(true);

        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({ values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
            <MYFORM onSubmit={handleSubmit} className="mx-auto">
              {console.log(values)}
              <Form.Group controlId="photo">
                
                <Form.File
                  custom
                  id="photo"
                  name="photo"
                  type="file"
                  label="Click Photo"
                  onChange={(event) => {

                    //setFieldValue("photo", event.currentTarget.files[0]);
                    setPhoto(event.currentTarget.files[0]);
                    //setSelectedPhoto(event.currentTarget.files[0]);
                    
                  }}
                  className="form-control" >
                </Form.File>

              </Form.Group>
            </MYFORM>
          )}
      </Formik>
      </Col>

      <Col sm className="text-left">
        <BUTTON style={{ marginTop: 1 + "em",  marginBottom: 1 + "em" }} onClick={analyzePhoto}>Analyze!</BUTTON>
      </Col>
      </Row>
      </Container>


      <Container className="text-center" style={{ width: '90%' }} >
        <Row>
          <Col sm className="text-center">
              <Thumb file={selectedPhoto} matrix3x3={matrix3x3} height={400} width={800} />
          </Col>
          <Col sm className="text-left">
         
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Number of Persons</td>
                  <td><h3>{numPerson} </h3></td>
                </tr>
                <tr>
                  <td>Confidence</td>
                  <td>{confidence.toFixed(1)}</td>
                </tr>
                <tr>
                  <td>Other objects detected</td>
                  <td> {articlesDetected}</td>
                </tr>
                <tr>
                  <td>Un-Safe</td>
                  <td>{unSafe}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>




    </CONTAINER>


  );
}

export default CrowdDetect;