import React from "react";
import PropTypes from "prop-types";
import MaterialTitlePanel from "./material_title_panel";
import {signOut} from "./App.js"
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faPowerOff, faTools, faUserMd, faChartBar, faPeopleArrows } from '@fortawesome/free-solid-svg-icons'

const styles = {
  sidebar: {
    width: 256,
    height: "100%"
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    height: "100%",
    backgroundColor: '#343a40'
  },
  button: { backgroundColor: "blue", borderColor: "grey", color: "#fff" }
};


const SidebarContent = props => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  const links = [];


  const handleSelect = (eventKey) => {
    //alert(`selected ${eventKey}`);
    props.handleSidebarSelect(eventKey);
    if(eventKey == 'signout')
    {
      signOut();
    }
  }

  for (let ind = 0; ind < 1; ind++) {
    links.push(
      <a key={ind} href="#" style={styles.sidebarLink}>
        Mock menu item {ind}
      </a>
    );
  }

  return (

      <div className='sidebar' style={styles.content}>



        <div className="sidebar-header">
          <FontAwesomeIcon icon={faUserCircle} />
                &nbsp;&nbsp;Hi <span id="userId">{props.eMailId}</span>
        </div>
        
        

        <div className="sidebar-content">
        <Nav justify  variant="pills" defaultActiveKey="testMain" className="flex-column" onSelect={handleSelect}>
          <Nav.Link eventKey="testMain"><FontAwesomeIcon icon={faUserMd} />&nbsp;&nbsp;&nbsp;Check</Nav.Link>
          <Nav.Link eventKey="statistics"><FontAwesomeIcon icon={faChartBar} />&nbsp;&nbsp;&nbsp;Stats</Nav.Link>
          <Nav.Link eventKey="crowddetect"><FontAwesomeIcon icon={faPeopleArrows} />&nbsp;&nbsp;&nbsp;Crowd</Nav.Link>
          <Nav.Link eventKey="settings"><FontAwesomeIcon icon={faTools} />&nbsp;&nbsp;&nbsp;Settings</Nav.Link>
          <Nav.Link eventKey="signout"><FontAwesomeIcon icon={faPowerOff} />&nbsp;&nbsp;&nbsp;Sign Out</Nav.Link>
        </Nav>
        </div>
        

      </div>

  );
};

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;
