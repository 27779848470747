import React from 'react';
import styled from 'styled-components';
import { Form, Button, Card, Container, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faBriefcaseMedical, faCog } from '@fortawesome/free-solid-svg-icons'

const CONTAINER = styled.div`
  background: #F7F9FA;
  height: auto;
  width: 100%;
  margin: 1em auto;
  color: snow;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);

  @media(min-width: 786px) {
    width: 100%;
  }

  label {
    color: #24B9B6;
    font-size: 1.2em;
    font-weight: 400;
  }

  h1 {
    color: #24B9B6;
    padding-top: .5em;
  }

  .form-group {
    margin-bottom: 2.5em;
  }

  .error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }
`;

const MYFORM = styled(Form)`
  width: 90%;
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;

  @media(min-width: 786px) {
    width: 50%;
  }
`;

const BUTTON = styled(Button)`
  background: #1863AB;
  border: none;
  font-size: 1.2em;
  font-weight: 400;

  &:hover {
    background: #1D3461;
  }
`;

// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// Schema for yup
const validationSchema = Yup.object().shape({
  measureDuration: Yup.number()
  .min(500, "*Duration must be between 500 and 5000 ms")
  .max(5000, "*Duration must be between 500 and 5000 ms")
  .required("*measureDuration required"),
  threshold: Yup.number()
  .min(35, "*Threshold must be between 35 and 42 degree celsuis")
  .max(42, "*Threshold must be between 35 and 42 degree celsuis")
  .required("*threshold required"), 
  thresholdAutoLow: Yup.number()
  .min(33, "*Threshold must be between 33 and 38 degree celsuis")
  .max(38, "*Threshold must be between 33 and 38 degree celsuis")
  .required("*threshold required"), 
  thresholdAutoHigh: Yup.number()
  .min(39, "*Threshold must be between 39 and 42 degree celsuis")
  .max(42, "*Threshold must be between 39 and 42 degree celsuis")
  .required("*threshold required")       
});

const Settings = (props) => {



  return (
    <CONTAINER>

        <Container  className="text-center" style={{width: '80%'}} >
      
        
      <Formik
        initialValues={{ threshold:"", measureDuration:"", thresholdAutoLow:"",thresholdAutoHigh:""}}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            debugger;
            

            // Simulate submitting to database, shows us values submitted, resets form
          setTimeout(() => {

            alert(JSON.stringify(values, null, 2));
            props.uploadDeviceSettings(values);
            
            resetForm();
            setSubmitting(false);
          }, 500);
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
        <MYFORM onSubmit={handleSubmit} className="mx-auto">
          {console.log(values)}

          <Row>
            <Col sm className="text-center">
              <Button variant="outline-success" onClick={props.setContinuousReadModeON}  size="lg" block>
                      FreeMode ON
              </Button> 
            </Col>
            <Col sm className="text-center">
              <Button variant="outline-danger" onClick={props.setContinuousReadModeOFF}  size="lg" block>
                      FreeMode OFF
              </Button> 
            </Col>        
          </Row>            
          <br></br>
          <br></br>

          <Row>
            <Col sm className="text-center">
              <Button variant="outline-success" onClick={props.setAutoModeON}  size="lg" block>
                      AutoMode ON
              </Button> 
            </Col>
            <Col sm className="text-center">
              <Button variant="outline-danger" onClick={props.setAutoModeOFF}  size="lg" block>
                      AutoMode OFF
              </Button> 
            </Col>        
          </Row> 
          <br></br>
          <br></br>                   

          
          <Form.Group controlId="formThreshold">
            <Form.Label>Fever Threshold :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="threshold"
              placeholder="(36.6°C): Temperature Threshold in °C"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.threshold}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.threshold && errors.threshold ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.threshold && errors.threshold ? (
                <div className="error-message">{errors.threshold}</div>
              ): null}
          </Form.Group>
          <Form.Group controlId="formThresholdAutoLow">
            <Form.Label>Auto Threshold Low :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="thresholdAutoLow"
              placeholder="(33.5°C): AutoMode Temperature Lower Threshold in °C"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.thresholdAutoLow}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.thresholdAutoLow && errors.thresholdAutoLow ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.thresholdAutoLow && errors.thresholdAutoLow ? (
                <div className="error-message">{errors.thresholdAutoLow}</div>
              ): null}
          </Form.Group> 
          <Form.Group controlId="formThresholdAutoHigh">
            <Form.Label>Auto Threshold High :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="thresholdAutoHigh"
              placeholder="(42°C): AutoMode Temperature High Threshold in °C"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.thresholdAutoHigh}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.thresholdAutoHigh && errors.thresholdAutoHigh ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.thresholdAutoHigh && errors.thresholdAutoHigh ? (
                <div className="error-message">{errors.thresholdAutoHigh}</div>
              ): null}
          </Form.Group>                   
          <Form.Group controlId="formMeasureDuration">
            <Form.Label>Measure Duration :</Form.Label>
            <Form.Control
              type="text"
              name="measureDuration"
              placeholder="(3000 ms): Duration in milli seconds"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.measureDuration}
              className={touched.measureDuration && errors.measureDuration ? "error" : null}
            />
            {touched.measureDuration && errors.measureDuration ? (
                <div className="error-message">{errors.measureDuration}</div>
              ): null}
          </Form.Group>
                                     
          <BUTTON variant="primary" type="submit" disabled={isSubmitting}>
            Submit
          </BUTTON>
 

        </MYFORM>
      )}
      </Formik>

      </Container>
      <MYFORM>
      <Container className="text-left" style={{width: '80%'}}>

       </Container>  
       </MYFORM>  
    </CONTAINER>
  );
}








export default Settings;