import React from 'react';
import { Card, Table } from 'react-bootstrap';
import Resizer from './image_resizer';

class Thumb extends React.Component {

  constructor(props) {
    super(props);
    

    // This binding is necessary to make `this` work in the callback
    this.handleEvent = this.handleEvent.bind(this);
  }

  handleEvent(thisUri) {
    this.setState({ loading: false, thumb: thisUri });
  }
  


    state = {
      loading: true,
      thumb: undefined,
    };
  
    componentWillReceiveProps(nextProps) {
      if (!nextProps.file) { return; }
  
      this.setState({ loading: true }, () => {
        let reader = new FileReader();
        var image = new Image();
  
        reader.onloadend = () => {
          //this.setState({ loading: false, thumb: reader.result });

          
          image.src = reader.result;

          image.onload =  () => {
            if (image.height > 720 || image.width > 1280) {

              Resizer.imageFileResizer(
                nextProps.file,
                1280,
                720,
                'JPEG',
                92,
                0,
                uri => {
                  //debugger;
                  console.log("DEBUG: Using Resizer");
                  this.setState({ loading: false, thumb: uri });
                  //handleEvent(uri);
                },
                'base64'
              );

            }
            else
            {
              this.setState({ loading: false, thumb: reader.result });
              //handleEvent(reader.result);
            }

          }


          //debugger;

        };
  
        reader.readAsDataURL(nextProps.file);
      });
    }
  
    render() {
      const { file, height, width, matrix3x3 } = this.props;
      const { loading, thumb } = this.state;
  
      /*if (!file) { return (<img src={thumb}
        className="img-thumbnail mt-2"
        height={height}
        width={width} />); }*/

      if (!file) {
        return (
          <Card className="bg-dark text-white text-left ">
            <Card.Img height={"200vw"} width={"200vw"} />
            <Card.ImgOverlay >
            <Card.Title >No Image Selected!</Card.Title>
            </Card.ImgOverlay>
          </Card>
        )

      } 
  
      if (loading) { return <p>loading...</p>; }
  
      /*return (<img src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        height={height}
        width={width} />);*/

      if (matrix3x3.length != 9) {
        return (
          <Card className="bg-dark text-white text-right ">
            <Card.Img src={thumb} alt="Card image" />
            <Card.ImgOverlay style={{ padding: "0" }}>

            </Card.ImgOverlay>
          </Card>
        );
      }

      return (
        <Card className="bg-dark text-white text-right ">
          <Card.Img src={thumb} alt="Card image" />
          <Card.ImgOverlay style={{padding:"0"}}>

               <Table style={{height:"100%", color:"#FFF", fontWeight:"600", textShadow:"2px 2px 3px #000000"}} striped bordered  hover  >
               <tr><td>{matrix3x3[0]}</td><td>{matrix3x3[1]}</td><td>{matrix3x3[2]}</td></tr>
                <tr><td>{matrix3x3[3]}</td><td>{matrix3x3[4]}</td><td>{matrix3x3[5]}</td></tr>
                <tr><td>{matrix3x3[6]}</td><td>{matrix3x3[7]}</td><td>{matrix3x3[8]}</td></tr>
               </Table>

          </Card.ImgOverlay>
        </Card>
      );
    }
  }

  export default Thumb;