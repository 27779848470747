/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { PubSub, Storage } from "aws-amplify";
import awsExports from "./aws-exports";
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { API, graphqlOperation, Auth, Predictions } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator } from 'aws-amplify-react'
//import styled from "styled-components";
import '@aws-amplify/ui/dist/style.css';
import Webcam from "react-webcam";
import Sidebar from "react-sidebar";
import MaterialTitlePanel from "./material_title_panel";
import SidebarContent from "./sidebar_content";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Navbar, Col, InputGroup} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserForm from './userForm';
import Stats from './stats';
import Settings from './settings';
import CrowdDetect from './crowddetect';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';



const mql = window.matchMedia(`(min-width: 800px)`);


Amplify.configure(awsExports);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-east-1',
  aws_pubsub_endpoint: 'wss://a10f3lkd2n224i-ats.iot.us-east-1.amazonaws.com/mqtt',
}));

const initialState = { name: '',age: '',gender: '',mobile: '',address: '', description: '' }

const MyTheme = {
  button: { backgroundColor: "blue", borderColor: "red" },
  
};
var THINGNAME = "ct20ina0008-D";


async function signOut() {
  try {
      await Auth.signOut({ global: true });
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

let eMailId = null;

Auth.currentAuthenticatedUser().then((user)=>{eMailId = user.attributes.email; });







class AppSideBar2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docked: mql.matches,
      open: false,
      sideBarSelected: 'testMain'
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
    this.onSetSideBarSelected = this.onSetSideBarSelected.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  onSetSideBarSelected(sideBarSelected) {
    this.setState({ sideBarSelected });
    //alert("Inside onSetSideBarSelected. Selected:"+sideBarSelected);
   // alert("Inside onSetSideBarSelected. State:"+this.state.sideBarSelected);
  }  

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }



  render() {

    
    const sidebar = <SidebarContent handleSidebarSelect={this.onSetSideBarSelected} 
                                    eMailId = {eMailId}
    />;

    const contentHeader = (
      <span >
        {!this.state.docked && (
          <a
            onClick={this.toggleOpen}
            href="#"
            
          >
            
          </a>
          
        )}
       
        <Navbar  className='justify-content-between'>
        <Button  onClick={this.toggleOpen}><FontAwesomeIcon icon={faAlignLeft} /> Menu</Button>
          <div className='contentheadercss'>ConnectedThinks</div> 
        </Navbar>
      </span>
    );

    const sidebarProps = {
      sidebar,
      docked: this.state.docked,
      open: this.state.open,
      onSetOpen: this.onSetOpen,
      styles:{ sidebar: { background: '#343a40', overflowY: "hidden" } }
    };

    return (
      <Sidebar {...sidebarProps}>
      
        
          <div className='mainContentCSS'>
          {contentHeader}
          <App sideBarState={this.state.sideBarSelected}/>
          </div>
       
        
      </Sidebar>
    );
  }
}



const App = (props) => {
  
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])
  const [userIdTs, setUserIdTs] = useState(0)
  const [userTempMeasure, setUserTempMeasure] = useState(0)
  const [deviceThresh, setDeviceThresh] = useState(0)
  const [deviceResult, setDeviceResult] = useState("---")
  const [readingState, setReadingState] = useState(-1)
  const [deviceDataValues, setDeviceDataValues] = useState()
  const [crowdDetectLabels, setCrowdDetectLabels] = useState()
  const [crowdDetectPhoto, setCrowdDetectPhoto] = useState({})

  console.log("App Props:"+props.sideBarState);

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  PubSub.subscribe('$aws/things/userdata/'+THINGNAME).subscribe({
    next: data => {
      console.log('Message received', data);
      setUserIdTs(JSON.stringify(data.value.epochTime));
      setUserTempMeasure("0");
      setDeviceThresh("0");
      setDeviceResult("---")
      setReadingState(1);
      setDeviceDataValues(null);
      
    },
    error: error => console.error(error),
    close: () => console.log('Done'),
  });

  PubSub.subscribe('$aws/things/devicedata/'+THINGNAME).subscribe({
    next: data => {
      //debugger;
      console.log('Message received', data);
      if(data.value.obj_temp != undefined)
      {
        setUserTempMeasure(parseFloat(data.value.obj_temp.toFixed(1)));
        setDeviceThresh(parseFloat(data.value.thresh.toFixed(1)))
        setDeviceResult(data.value.res)
        setReadingState(0);
        setDeviceDataValues(data.value);
      }
    },
    error: error => console.error(error),
    close: () => console.log('Done'),
  });  

  async function uploadUserData(values)
  {
    debugger;
    //await PubSub.publish('$aws/things/devicedata/'+THINGNAME, { msg: 'Hello!'+new Date().toLocaleString() }); 

   var userData = {
      Name: values.name,
      Age : values.age,
      Sex : values.gender,
      URL : "https://ctuserstoragedevl-devl.s3.amazonaws.com/public/"+userIdTs+'.jpg',
      Mobile: values.phone,
      ID    : values.govid,
      Email : values.email
  }



  var publishData = {
    "epochTime": parseInt(userIdTs),
    "userData" : userData,
    "assetId": THINGNAME,
    "rt":1
  };
  console.log("Publishing..",publishData);   
  await PubSub.publish('$aws/things/devicedata/'+THINGNAME,publishData); 

      
  Storage.put(userIdTs+'.jpg', values.photo, {level:'public',
    contentType: 'image/jpeg'
  })
  .then (result => console.log(result))
  .catch(err => console.log(err));
    
  }

  async function setCrowdDetectPhotoFunc(photo)
  {
    debugger;
    setCrowdDetectPhoto(photo)
  }

  async function uploadCrowdDetectPhoto(photo)
  {

    /*let imageId = (new Date().getTime()/1000).toFixed(0);
      
    Storage.put(imageId+'-crowd.jpg', values.photo, {level:'public',
      contentType: 'image/jpeg'
    })
    .then (result => {
      console.log(result);
      console.log("URL:   "+"https://ctuserstoragedevl-devl.s3.amazonaws.com/public/"+imageId+'-crowd.jpg')
    })
    .catch(err => console.log(err));*/

    //setCrowdDetectLabels(null);
     var file = photo;
    Predictions.identify({
      labels: {
        source: {
          file
        },
        type:"ALL"
      }
    })
    .then((response) => 
    {
      alert("Crowd Detection Complete");
      setCrowdDetectLabels(response);
      const { labels } = response;
      const { unsafe } = response; // boolean 
      labels.forEach(object => {
        const { name, boundingBoxes } = object
      });
      //debugger;            
      console.log({ response });
    }
    )
    .catch(err => {
      debugger;
      console.log({ err })
    }
    );    
    
  }  

  async function uploadDeviceSettings(values)
  {
    console.log(values);
    var configData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "thresh" : values.threshold,
      "measureDuration": values.measureDuration,
      "autoModeThreshLow": values.thresholdAutoLow,
      "autoModeThreshHigh": values.thresholdAutoHigh,
     };
    console.log("Publishing..",configData);   
    await PubSub.publish('$aws/things/configdata/'+THINGNAME,configData);     
    debugger;
    //await PubSub.publish('$aws/things/devicedata/'+THINGNAME, { msg: 'Hello!'+new Date().toLocaleString() }); 

    
  }  

  async function startMeasurement()
  {
    var publishData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "cmd" : "start",
    };
    console.log("Publishing..",publishData);   
    await PubSub.publish('$aws/things/devicecommand/'+THINGNAME,publishData);     

  }

  async function setContinuousReadModeON()
  {
    var publishData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "cmd" : "contiModeON",
    };
    console.log("Publishing..",publishData);   
    await PubSub.publish('$aws/things/devicecommand/'+THINGNAME,publishData);
    
  }
  async function setContinuousReadModeOFF()
  {
    var publishData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "cmd" : "contiModeOFF",
    };
    console.log("Publishing..",publishData);   
    await PubSub.publish('$aws/things/devicecommand/'+THINGNAME,publishData);
    
  }  

  async function setAutoModeON()
  {
    var publishData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "cmd" : "autoModeON",
    };
    console.log("Publishing..",publishData);   
    await PubSub.publish('$aws/things/devicecommand/'+THINGNAME,publishData);
    
  }

  async function setAutoModeOFF()
  {
    var publishData = {
      "epochTime": parseInt((new Date().getTime()/1000)),
      "cmd" : "autoModeOFF",
    };
    console.log("Publishing..",publishData);   
    await PubSub.publish('$aws/things/devicecommand/'+THINGNAME,publishData);
    
  }  

  async function fetchTodos() {
    try {

      Auth.currentCredentials().then((info) => {
        const cognitoIdentityId = info.identityId; console.log(cognitoIdentityId);
        
      }); 
      
      Auth.currentUserInfo().then((info) => {
        const eMailId = info.attributes.email;
      });   





      

      const todoData = await API.graphql(graphqlOperation(listTodos))
      //debugger; 
      const todos = todoData.data.listTodos.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }

  async function addTodo() {
    try {
      await PubSub.publish('$aws/things/userdata/'+THINGNAME, { msg: 'Hello!'+new Date().toLocaleString() }); 
      
      Storage.put('test.txt', 'Hello')
      .then (result => console.log(result)) // {key: "test.txt"}
      .catch(err => console.log(err));

      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  function UserFormDiv() {
    return (
      <UserForm userIdTs = {userIdTs} 
      userTempMeasureDegC={userTempMeasure}
      userTempMeasureDegF={parseFloat(((userTempMeasure*9/5)+32).toFixed(1))}
      deviceThresh = {deviceThresh}  
      deviceResult = {deviceResult}
      readingState = {readingState}
      uploadUserData = {uploadUserData}
      startMeasurement = {startMeasurement}
      setContinuousReadModeON = {setContinuousReadModeON}
      setContinuousReadModeOFF = {setContinuousReadModeOFF}
      setAutoModeON = {setAutoModeON}
      setAutoModeOFF = {setAutoModeOFF}
      />
    );
  }

  function StatsDiv() {
    return (


      <Stats sideBarState = {props.sideBarState}
             deviceDataValues = {deviceDataValues}
             
             />

    );
  }  

  function CrowdDetectDiv() {
    return (


      <CrowdDetect 
             uploadCrowdDetectPhoto = {uploadCrowdDetectPhoto}
             crowdDetectLabels = {crowdDetectLabels}
             crowdDetectPhoto = {crowdDetectPhoto}
             setCrowdDetectPhotoFunc = {setCrowdDetectPhotoFunc}

             
             />

    );
  } 
  function SettingsDiv() {
    return (
      <Settings 
      uploadDeviceSettings = {uploadDeviceSettings}
      setContinuousReadModeON = {setContinuousReadModeON}
      setContinuousReadModeOFF = {setContinuousReadModeOFF}
      setAutoModeON = {setAutoModeON}
      setAutoModeOFF = {setAutoModeOFF}
      />
    );
  }
  
  function DisplayDiv()
  {
    
    if(props.sideBarState == 'settings')
    {
      return (<SettingsDiv/>);
    }
    else if(props.sideBarState == 'statistics')
    {
      return (<StatsDiv/>);
    } 
    else if(props.sideBarState == 'crowddetect')
    {
      return (<CrowdDetectDiv
         />);
    }    
    else
    {
      return (<UserFormDiv/>);
    }   
  }

  return (
    <div >

     {/*<WebcamCapture/>*/}

     <DisplayDiv/>


     {/* 
 
      <h2>Amplify Todos</h2>
      <input
        onChange={event => setInput('name', event.target.value)}
        style={styles.input}
        value={formState.name} 
        placeholder="Full Name"
      />
                         
      <input
        onChange={event => setInput('description', event.target.value)}
        style={styles.input}
        value={formState.description}
        placeholder="Remarks"
      />
      <Button  onClick={addTodo}>Create Entry</Button>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoName}>{todo.name}</p>
            <p style={styles.todoName}>{todo.age}</p>
            <p style={styles.todoName}>{todo.gender}</p>
            <p style={styles.todoName}>{todo.mobile}</p>
            <p style={styles.todoName}>{todo.address}</p>
            <p style={styles.todoDescription}>{todo.description}</p>
          </div>
        ))
      } 
      */}  
    </div>
 
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

class S3ImageUpload extends React.Component {
  onChange(e) {
      const file = e.target.files[0];
      Storage.put('example.png', file, {
          contentType: 'image/png'
      })
      .then (result => console.log(result))
      .catch(err => console.log(err));
  }

  render() {
      return (
          <input
              type="file" accept='image/png'
              onChange={(evt) => this.onChange(evt)}
          />
      )
  }
}



const SignupForm1 = () => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="fullName">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" placeholder="First Last" onChange={this.myChangeHandler} />
        </Form.Group>
      <label htmlFor="firstName">First Name</label>
      <input
        id="firstName"
        name="firstName"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
      />
      {formik.touched.firstName && formik.errors.firstName ? (
        <div>{formik.errors.firstName}</div>
      ) : null}
      <label htmlFor="lastName">Last Name</label>
      <input
        id="lastName"
        name="lastName"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
      />
      {formik.touched.lastName && formik.errors.lastName ? (
        <div>{formik.errors.lastName}</div>
      ) : null}
      <label htmlFor="email">Email Address</label>
      <input
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email ? (
        <div>{formik.errors.email}</div>
      ) : null}
      <button type="submit">Submit</button>
    </Form>
  );
};

class FormUserInputs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      sex: '',
      ageGroup:'',
      eMail:'',
      address:'',
      remarks:'' 
    };
  }

  myChangeHandler = (event) => {
    //debugger;
    let nam = event.target.id;
    let val = event.target.value;
    this.setState({[nam]: val});
  }  

  handleSubmit = (event) => {
    const form = event.currentTarget;
    debugger;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    //setValidated(true);
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="fullName">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" placeholder="First Last" onChange={this.myChangeHandler} />
        </Form.Group>
        <Form.Group controlId="sex">
          <Form.Label>Sex</Form.Label>
          <Form.Control as="select">
            <option>Male</option>
            <option>Female</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="ageGroup">
          <Form.Label>Age Group</Form.Label>
          <Form.Control as="select">
            <option>Less than 5 Yrs</option>
            <option>5 to 15</option>
            <option>15 to 40</option>
            <option>40 to 60</option>
            <option>Above 60</option>
          </Form.Control>
        </Form.Group>        
        <Form.Group controlId="eMail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="name@example.com" />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>Address</Form.Label>
          <Form.Control as="textarea" rows="2" />
        </Form.Group>        
        <Form.Group controlId="remarks">
          <Form.Label>Remarks</Form.Label>
          <Form.Control as="textarea" rows="2" />
        </Form.Group>
        <Form.Group >
          <Button variant="primary" type="submit">Submit</Button>
        </Form.Group>
      </Form>
    )
  }
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};
 
const WebcamCapture = () => {
  const webcamRef = React.useRef(null);
 
  const capture = React.useCallback(
    async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      var res1 = await fetch(imageSrc).then(res => res.blob()).then();
      
      const blobUrl = URL.createObjectURL(res1); 
      Storage.put('me.jpeg', res1, {
        contentType: 'image/jpeg'
    })
    .then (result => console.log(result))
    .catch(err => console.log(err));       
          
      //debugger;
    },
    [webcamRef]
  );
 
  return (
    <>
      <Webcam
        audio={false}
        height={200}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={356}
        videoConstraints={videoConstraints}
      />
      <Button variant="primary" onClick={capture}>Capture photo</Button>
    </>
  );
};

export default withAuthenticator(AppSideBar2,false, [], null, MyTheme)

export { signOut}




