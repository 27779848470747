import React from 'react';

import { Form, Button, Card, Container, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faBriefcaseMedical, faCog } from '@fortawesome/free-solid-svg-icons'
import Thumb from './thumbnail'
import {CONTAINER, MYFORM, BUTTON} from './style-components'



// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

// Schema for yup
const validationSchema = Yup.object().shape({
  file: Yup.string(),
  name: Yup.string()
  .min(2, "*Names must have at least 2 characters")
  .max(100, "*Names can't be longer than 100 characters")
  .required("*Name is required"),
  govid: Yup.string()
  .min(5, "*Names must have at least 5 characters")
  .max(20, "*Names can't be longer than 20 characters"),
  email: Yup.string()
  .email("*Must be a valid email address")
  .max(100, "*Email must be less than 100 characters")
  .required("*Email is required"),
  phone: Yup.string()
  .matches(phoneRegExp, "*Phone number is not valid")
  .required("*Phone number required"),
  address: Yup.string()
  .min(2, "*Names must have at least 2 characters")
  .max(100, "*Names can't be longer than 100 characters"),
  age: Yup.number()
  .min(0, "*Age must be between 0 and 100")
  .max(100, "*Age must be between 0 and 100")
  .required("*Age required"),
  gender: Yup.mixed()
  .oneOf(['Male','Female','Other'],"*Must enter either Male, Female or Other")
  .required("*Gender required")    
});

const UserForm = (props) => {

  let colorResult = "#007bff"; //Or "#eb4034"
  let iconResult  = faThumbsUp; // Or faExclamationTriangle
  let iconColorResult = "#01796F"; //Or "#eb4034"
  let iconEffect = false; 
  let readingState = "Press button to start..."
  let readingStartButton = "Start Reading"
  let readingStartButtonState = false;
  let readingStateColor = "";
  let userTempMeasureDegF = props.userTempMeasureDegF;
  let userTempMeasureDegC = props.userTempMeasureDegC;

  if(props.userTempMeasureDegC <= props.deviceThresh)
  {
     colorResult = "#007bff"; //Or "#eb4034"
     iconResult  = faThumbsUp; // Or faExclamationTriangle
     iconColorResult = "#01796F"; //Or "#eb4034"
  }
  else
  {
     colorResult = "#eb4034"
     iconResult  = faBriefcaseMedical
     iconColorResult =  "#eb4034"
  }

  if(props.readingState == 1)
  {
    readingState = "New reading in progress.."
    readingStartButton = "Reading in progress.."
    readingStartButtonState = true;
    readingStateColor = colorResult;
    userTempMeasureDegF = "---";
    userTempMeasureDegC = "---";   
    iconResult = faCog 
    iconEffect = true;
    iconColorResult = "#007bff"
  }
  else if(props.readingState == 0)
  {
    readingState= "Reading complete!"
    readingStartButton = "Start Reading"
    readingStartButtonState = false
    readingStateColor = colorResult;
  }
  else if(props.readingState == -1)
  {
    userTempMeasureDegF = "---";
    userTempMeasureDegC = "---";
  }

  return (
    <CONTAINER>

      <Container  className="text-center" style={{width: '50%'}} >
          <h1 style={{color:readingStateColor}}>{readingState}</h1>;
          <hr />
          <Row>
 
                <Button onClick={props.startMeasurement} variant="primary" size="lg" block disabled={readingStartButtonState}>
                  {readingStartButton}
                </Button>
          </Row>
          <br></br>
          <hr />
      </Container>
      
      
      <Container  style={{width: '50%'}} >
      <Row>
        <Col sm className="text-center"><h1 style={{color:colorResult}}>{userTempMeasureDegF}°F </h1></Col>
        <Col sm className="text-center">
        <FontAwesomeIcon icon={iconResult}  style={{color:iconColorResult}} size="4x" spin={iconEffect}/>
        </Col>
        <Col sm className="text-center"><h1 style={{color:colorResult}}>{userTempMeasureDegC}°C </h1></Col>
      </Row>
      <hr />
      </Container>
    
      
      
      <Formik
        initialValues={{ photo:"", name:"", email:"", phone:"", address:"", age:"",gender:"", govid:""}}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            debugger;
            

            // Simulate submitting to database, shows us values submitted, resets form
          setTimeout(() => {

            alert(JSON.stringify(values, null, 2));
            props.uploadUserData(values);
            
            resetForm();
            setSubmitting(false);
          }, 500);
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
        <MYFORM onSubmit={handleSubmit} className="mx-auto">
          {console.log(values)}

          
          <Form.Group controlId="formName">
            <Form.Label>Name :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="name"
              placeholder="Full Name"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.name}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.name && errors.name ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.name && errors.name ? (
                <div className="error-message">{errors.name}</div>
              ): null}
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email :</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={touched.email && errors.email ? "error" : null}
            />
            {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ): null}
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Phone :</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              className={touched.phone && errors.phone ? "error" : null}
              />
              {touched.phone && errors.phone ? (
                <div className="error-message">{errors.phone}</div>
              ): null}
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Address :</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder="Address:"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              className={touched.address && errors.address ? "error" : null}
              />
              {touched.address && errors.address ? (
                <div className="error-message">{errors.address}</div>
              ): null}
          </Form.Group>
          <Form.Group controlId="formAge">
            <Form.Label>Age :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="age"
              placeholder="Age: 0 to 100"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.age}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.name && errors.age ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.age && errors.age ? (
                <div className="error-message">{errors.age}</div>
              ): null}
          </Form.Group>   
          <Form.Group controlId="formGender">
            <Form.Label>Sex :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="gender"
              placeholder="Male, Female or Other"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.gender}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.gender && errors.gender ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.gender && errors.gender ? (
                <div className="error-message">{errors.gender}</div>
              ): null}
          </Form.Group> 
          <Form.Group controlId="formGOVID">
            <Form.Label>GOV ID :</Form.Label>
            <Form.Control
              type="text"
              /* This name property is used to access the value of the form element via values.nameOfElement */
              name="govid"
              placeholder="Aadhar/DL/Passport"
              /* Set onChange to handleChange */
              onChange={handleChange}
              /* Set onBlur to handleBlur */
              onBlur={handleBlur}
              /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
              value={values.govid}
              /* Check if the name field (this field) has been touched and if there is an error, if so add the .error class styles defined in the CSS (make the input box red) */
              className={touched.govid && errors.govid ? "error" : null}
              />
              {/* Applies the proper error message from validateSchema when the user has clicked the element and there is an error, also applies the .error-message CSS class for styling */}
              {touched.govid && errors.govid ? (
                <div className="error-message">{errors.govid}</div>
              ): null}
          </Form.Group>          
          <Form.Group controlId="photo">
            <Form.Label>Photo:</Form.Label>
            <Form.File
              custom  
              id="photo" 
              name="photo" 
              type="file" 
              label="Click to Upload Photo"
              onChange={(event) => {
                        setFieldValue("photo", event.currentTarget.files[0]);
                      }} 
              className="form-control" >
            </Form.File>
            <Thumb file={values.photo} height={200} width={200}/>
          </Form.Group>                           
          <BUTTON variant="primary" type="submit" disabled={isSubmitting}>
            Submit
          </BUTTON>
        </MYFORM>
      )}
      </Formik>
    </CONTAINER>
  );
}












export default UserForm;