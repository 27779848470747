import React from 'react';
import { Container, Table} from 'react-bootstrap';
import { Chart } from "react-google-charts";
import styled from 'styled-components';

const CONTAINER = styled.div`
  background: #F7F9FA;
  height: auto;
  width: 100%;
  margin: 1em auto;
  color: snow;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);

  @media(min-width: 786px) {
    width: 100%;
  }

  label {
    color: #24B9B6;
    font-size: 1.2em;
    font-weight: 400;
  }

  h1 {
    color: #24B9B6;
    padding-top: .5em;
  }

  h2 {
    color: #24B9B6;
    padding-top: .5em;
  }

  .form-group {
    margin-bottom: 2.5em;
  }

  .error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }
`;

const Stats = (props) => {

 let values = JSON.stringify(props.deviceDataValues)
 let dataArr = [['Count', 'Temperature', 'Threshold']];

 //debugger;

 if(props.deviceDataValues == undefined)
 {
    return (
        <CONTAINER>  
          <Container>
            <h1>Last Reading Unavailable</h1>
            <br></br>
            <br></br>
          </Container>
        </CONTAINER>
    );

 }

 for(var i = 0 ; i < props.deviceDataValues.obj_temp_arr.length; i++)
 {
    dataArr.push([i+1,props.deviceDataValues.obj_temp_arr[i],props.deviceDataValues.thresh])
 }
 
 //debugger;
  return (
    <CONTAINER>  
      <Container>
    <h1>Last Reading Statistics</h1>


    <Table striped bordered hover responsive>
        <thead>
            <tr>
            <th>#</th>
            <th>Parameter</th>
            <th>Value</th>
             </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Human Temperature</td>
            <td>{props.deviceDataValues.obj_temp.toFixed(2)} °C</td>
            </tr>
            <tr>
            <td>2</td>
            <td>Ambient Temperature</td>
            <td>{props.deviceDataValues.amb_temp.toFixed(2)} °C</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Threshold Temperature</td>
            <td>{props.deviceDataValues.thresh.toFixed(2)} °C</td>
            </tr>
            <tr>
            <td>4</td>
            <td>Date Time</td>
            <td>{new Date(props.deviceDataValues.epochTime*1000).toLocaleString()}</td>
            </tr>            
            <tr>
            <td>5</td>
            <td>Asset ID</td>
            <td>{props.deviceDataValues.assetId}</td>
            </tr>
        </tbody>
    </Table>
  



    <Chart
        width={'100%'}
        height={'300px'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={dataArr}
        options={{
            title: 'Temperature Readings',
            hAxis: { title: 'Count', titleTextStyle: { color: '#333' } },
            vAxis: { minValue: 30 },
            // For the legend to fit, we make the chart area smaller
            chartArea: { width: '50%', height: '70%' },
            // lineWidth: 25
            colors:["green"],
            seriesType: 'bars',
            series: {1: {color:'red', type: 'line',  lineDashStyle: [3, 3], lineWidth: 1}  },
        }}
        
        // For tests
        rootProps={{ 'data-testid': '1' }}
        />
   
    </Container>
    </CONTAINER>
  );
}

export default Stats;