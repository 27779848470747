// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f695cb85-0e1b-4638-8790-5affee49bc7e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_B2FDasfY4",
    "aws_user_pools_web_client_id": "1rl1bkv2ofklv5h97c666vsugu",
    "oauth": {},
    "aws_user_files_s3_bucket": "mobileiotstoragejundev-jundev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "identify": {
            "identifyEntities": {
                "proxy": false,
                "region": "us-east-1",
                "celebrityDetectionEnabled": true
            },
            "identifyLabels": {
                "proxy": false,
                "region": "us-east-1",
                "defaults": {
                    "type": "ALL"
                }
            }
        }
    }
};


export default awsmobile;
